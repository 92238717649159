import React, { useContext} from 'react'
import { GlobalDataContext } from '../../context/context'

import { HiChevronRight } from 'react-icons/hi'
import { BiChevronRightSquare } from 'react-icons/bi'

import {ButtonContent} from '../global/boton/ButtonContent'

const BlockContent = ({ title, sloganPrincipal, listServices, listAbout, texts, bgImg1, bgImg2 }) => {

  const { rpdata } = useContext(GlobalDataContext)


  return (
    <section className="flex flex-col md:flex-row h-full py-0 md:py-20">
      <div
        className="bg-cover bg-center bg-no-repeat w-full md:w-[40%] py-[200px] md:py-0 h-full md:h-screen"
        style={{
          backgroundImage: `url("${bgImg1}")`,
          clipPath: 'polygon(0 0, 75% 27%, 75% 90%, 0% 100%)'
        }}
        data-aos="flip-left"
        data-aos-duration="2000"
      ></div>

      <div className="w-full md:w-[40%] px-5 mx-0 pt-10 md:pt-0 md:-mx-20 flex flex-col justify-center items-center">

        {
          sloganPrincipal ?
            rpdata?.dbPrincipal?.licensed ?
              <h1 className='text-[35px] md:text-[50px] text-center py-5'>{rpdata?.dbPrincipal?.licensed}</h1>
              :
              <h1 className='text-[35px] md:text-[50px] text-center py-5'>
                {
                  rpdata?.dbPrincipal?.exprYears ?
                    `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                    : 'we have many years of experience'
                }
              </h1>
            :
            <h1 className='text-[35px] md:text-[50px] text-center py-5'>{title}</h1>
        }
        <p className="text-center">{texts}</p>

        {
          listServices ?
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-5 py-10 px-5">
              {rpdata?.dbServices?.slice(0, 8).map((item, index) => {
                return (
                  <li key={index} className="flex items-center gap-3">
                    <BiChevronRightSquare color="#0077FF" />
                    <span>{item.name}</span>
                  </li>
                )
              })}
            </ul>
            : null
        }

        {
          listAbout ?
            <ul className='grid grid-cols-1 md:grid-cols-2 gap-5 py-10 px-5 md:px-16'>
              {
                rpdata?.dbAbout?.[0].list.length > 1 ?
                  rpdata?.dbAbout?.[0].list.map((item, index) => {
                    return (

                      <li key={index} className="flex items-center gap-1">
                        <HiChevronRight />
                        <span>{item}</span>
                      </li>
                    )
                  })
                  : null
              }
            </ul>
            : null
        }
        <div className="text-center py-5">
          <ButtonContent btnStyle={'three'} />
        </div>

      </div>

      <div
        className="hidden md:block bg-cover bg-center bg-no-repeat w-full md:w-[40%] py-[200px] md:py-0 h-full md:h-screen"
        style={{
          backgroundImage: `url("${bgImg2}")`,
          clipPath: 'polygon(26% 27%, 100% 0, 100% 100%, 25% 90%)'
        }}
        data-aos="flip-right"
        data-aos-duration="2000"
      ></div>

    </section>
  )
}

export default BlockContent